.shop-evoshield {
    padding: 90px 0 40px;
    background: #fff;
    min-height: 88vh;
    .page-title {
        margin-top: 75px;
        margin-bottom: 50px;
    }
    .product-info {
        max-width: 300px;
        border: 1px solid #000;
        img {
            width: 100%;
        }
        h4 {
            font-size: 16px;
            text-transform: uppercase;
            line-height: 30px;
            font-weight: 300;
        }
        span {
            font-weight: 600;
            font-size: 18px;
        }
    }
}
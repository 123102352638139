.card-form-input {
    border: 1px solid #e2e2e2;
    padding: 15px 10px;
    min-height: 49px;
    border-radius: 5px;
}
.pay-button {
    background: #0056b2 !important;
    padding: 10px !important;
    margin-top: 20px !important;
    margin-bottom: 0 !important;
    color: #fff;
    width: 100%;
    max-width: 100% !important;
    height: auto !important;
    &:hover {
        color: #ffffff !important;
    }
}

.stripe-card-element-from {
    form {
        display: inline-block !important;
        width: 100%;
    }
    p.text-danger.invalid-error{
        font-style: unset !important;
        font-size: 15px;
        font-weight: 300;
        text-align: left;
        margin-bottom: 12px;
    }
    p.card-error {
        font-style: unset !important;
        font-size: 15px;
        font-weight: 300;
        text-align: left;
        margin-bottom: 0;
    }
}
.selected-card {
    .card {
        border: 2px solid #000;
        .card-header {
            border-bottom: 2px solid #000;
            background-color: #000;
            color: #fff;
        }
    }
}
.details-cards {
    width: 100%;
    .card-header {
        background: transparent;
        text-align: center;
        font-size: 23px;
        font-weight: 600;
    }
    .card-body {
        text-align: center;
        font-weight: 400;
        line-height: 30px;
    }
}
@import "../../../assets/variables.scss";

@mixin align-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.home-body{
    background-color: #fff;
}
.inner-header {
    position: relative;
}
.header-sticky{
    background: $bg-white;
    // linear-gradient(90deg, #191919 10%, #000000);
}
.navbar-toggler {
    background: $bg-dark;
    border: 1px solid $bg-dark;
    padding: 0.6rem 0.75rem;
}
.site-header {
    padding: 17px 0;
    position: fixed;
    width: $full-width;
    top: 0;
    z-index: 1000;
    box-shadow: -13px -6px 20px 5px #dedede;
    .container-fluid {
        max-width: 99%;
        // max-width: 1300px;
        .site-logo {
            img {
                // max-width: 225px;
                // height: 41px;
                // object-fit: cover;
                // object-position: top;
                // width: $full-width;
                // max-width: 225px;
                height: 39px;
                // object-fit: cover;
                // object-position: top;
                // width: 200px;
            }
        }
        .header-layout {
            @include align-center;
            .mobile-menu {
                display: none;
            }
            .main-menu {
                .navbar-nav {
                    align-items: $align-items;
                    a.nav-link {
                        padding: 0 15px;
                        font-size: $font-size-17;
                        font-family: $font-family;
                        font-weight: $font-weight-bold;
                        color: $text-dark;
                        display: $display;
                    }
                    a.nav-link.btn {
                        background: $transparent;
                        border: 1px solid $bg-dark;
                        padding: 6px 30px !important;
                        transition: $transition;
                        color: $text-dark;
                        font-weight: $font-weight-Regular;
                        &:hover {
                            background: $bg-dark;
                            color: $text-white;
                            border-color: $bg-dark;
                        }
                    }
                    .dropdown.user.user-menu{
                        margin-left: 13px;
                    }
                }
            }
            @media screen and (max-width: 991px) {
                justify-content: $justify-content;
            }
        }
    }
}

.user-pr-pop{
    .dropdown-menu{
        top: 12px !important;
        transform: translate3d(-188px, 45px, 0px) !important;
        width: 240px !important;
        padding: 6px !important;
        box-shadow: 2px 0px 8px #adb5bd;
        .card {
            padding: 0!important;
            .user-pf-item{
                color: #696969 !important;
                font-size: 14px !important;
                line-height: 16px !important;
                margin: 0px 0px !important;
                font-weight: 400 !important;
                font-family: sans-serif !important;
                padding: 12px 14px !important;
                display: flex;
                &:hover {
                    background: #e9ecef;
                 }
                svg{
                    margin-right: 1rem;
                    font-size: 1rem;
                    vertical-align: -17%;
                }
                
            }
        }
        .user-pf-pop-head{
            border-bottom: 0.8px solid #adb5bd;
            padding: 12px 10px;
            .profile-user-name-2{
                background: $primary-color;
                color: #fff;
                font-weight: 600;
                width: 45px;
                height: 45px;
                font-size: 18px;
                line-height: 47px;
                letter-spacing: -1px;
                text-transform: capitalize;
                text-align: center;
                display: inline-block;
            }
            .pl-pf-dt{
                padding: 10px;
                font-size: 18px;
                font-weight: 500;
                p{
                    margin:0!important;
                }
                
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    body.mobile-menu-overlay{
        &:after {
            content: " ";
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: #07070747;
            width: 100%;
        }
    }
    .header-layout {
        .main-menu {
            nav.navbar {
                padding: $padding;
                button {
                    padding: $padding;
                    position: $position-rel;
                    top: 4px;
                    border: $border;
                    &:focus,
                    &:hover {
                        background: $transparent;
                        border: $border;
                        outline: 0;
                    }
                }
            }
        }
      
        .mobile-menu {
            background: $transparent;
            position: fixed;
            width: 240px;
            height: $full-height;
            top: 0;
            z-index: 1000;
            right: -100%;
            transition: all 0.3s ease;
            display: block!important;
            color: #fff;
           
            .nsf1 {
                background: #fff;
                height: 100%;
                position: relative;
                z-index: 999;
                border-left: 1px solid $bg-dark;
            }
            .nsf {
                background: #fff;
                padding: 10px;
                display: $display-flex;
                align-items: $align-items;
                height: 56px;
                button {
                    background: $transparent;
                    width: 45px;
                    height: 45px;
                    display: $display;
                    margin-left: auto;
                    border-radius: 100%;
                    position: $position-absol;
                    top: 0;
                    right: 6px;
                    border: none;
                    // svg {
                    //     width: $width-20;
                    //     height: $height-20;
                    //     fill: gray;
                    // }
                    img{
                        width: 21px;
                        filter: grayscale(1) invert(.9);
                    }
                }
                span {
                    display: $display;
                    width: 120px;
                    height: 120px;
                    margin: $margin-auto;
                    color: $white-color;
                    font-weight: $font-weight-Semi-bold;
                    letter-spacing: 5px;
                    font-size: 45px;
                    font-family: $font-family;
                    text-transform: $text-transform;
                    border: 5px solid #fff;
                    border-radius: 100px;
                    display: $display-flex;
                    align-items: $align-items;
                    justify-content: $justify-content-center;
                }
            }
            ul {
                display: $display;
                a {
                    // border-bottom: 1px solid #258ecd;
                    // color: #fff;
                    padding: 15px 15px 15px 41px;
                    font-size: $font-size-17;
                    transition: $transition;
                    border-bottom: none;
                    color: $text-dark;
                    font-weight: $font-weight-Light;
                    position: relative;
                    // &:hover {
                    //     background: #258ecd;
                    // }
                    // img.menuIcons{
                    //     vertical-align: middle;
                    //     width: 15px;
                    // }
                    // span{
                    //     vertical-align: middle;
                    // }
                    &:before{
                        content: "";
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        left: 19px;
                        top: 50%;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        transform: translateY(-50%);
                       
                    }

                }
                a.home-menu{
                    &:before{
                        background-image: url('./icons/ic-home.png');
                       
                    }
                }
                a.howitwork-menu{
                    &:before{
                        background-image: url('./icons/ic-how-it-works.png');
                       
                    }
                }
                a.validtion-menu{
                    &:before{
                        background-image: url('./icons/ic-validation.png');
                       
                    }
                }
                a.contact-menu{
                    &:before{
                        background-image: url('./icons/ic-contact-us.png');
                       
                    }
                }
                a.dashboard-menu{
                    &:before{
                        background-image: url('./icons/ic-dashboard.png');
                       
                    }
                }
                a.buy-menu{
                    &:before{
                        background-image: url('./icons/bag-fill.svg');
                       
                    }
                }
                a.profile-menu{
                    &:before{
                        background-image: url('./icons/ic-profile.png');
                       
                    }
                }
                a.sign-menu{
                    &:before{
                        background-image: url('./icons/ic-sign-in.png');
                       
                    }
                }
            }
        }
        .mobile-menu.active {
            right: 0;
        }
    }
}
@media screen and (min-width : 576px) and (max-width: 991px) {
    .mobile-menu{
        ul{
            
                height: 330px;
                overflow: auto;
        }
    }
}

@import '../../../assets/variables.scss';

@mixin align-center {
	display: flex;
    flex-wrap: wrap;
	align-items: center;
}

.newsletter {
    text-align: $text-align;
    padding: 60px $padding 40px;
    h3 {
        font-size: 36px;
        font-family: $font-family;
        margin-bottom: 24px;
        font-weight: $font-weight-Semi-bold;
        color: $text-color;
        display: inline-block;
    }
    form {
        max-width: 540px;
        margin: $margin-auto;
        width: $full-width;
        .form-group {
            display: $display-flex;
            margin: $margin;
            input {
                width: $full-width;
                height: 48px;
                font-size: $font-size-16;
                font-weight: $font-weight-Regular;
                padding: $padding 20px;
                border-radius: $border-radius;
                margin-bottom: 24px;
                color: #808080;
                border-top-right-radius: $border;
                border-bottom-right-radius: $border;
                &:focus {
                    border-color: #808080;
                    box-shadow: none;
                    outline: 0;
                }
            }
            button {
                background: $transparent;
                border: 1px solid $bg-dark;
                font-size: $font-size-19;
                font-weight: $font-weight-Semi-bold;
                transition: $transition;
                width: $full-width;
                max-width: 172px;
                color: $text-dark;
                height: 48px;
                border-top-left-radius: $border;
                border-bottom-left-radius: $border;
                &:focus {
                    box-shadow: none;
                    background: $bg-dark;
                    color: $text-white;
                    outline: 0;
                }
                &:hover {
                    box-shadow: none;
                    background: $bg-dark;
                    color: $text-white;
                    outline: 0;
                }
            }
        }
    }
    p {
        font-family: $font-family;
        font-size: $font-size-18;
        color: $secondary-color;
        font-weight: $font-weight-Regular;
        margin: $margin;
        a {
            color: $color;
        }
    }
}

.site-footer {
    background: $bg-white;
    box-shadow: 20px 7px 20px 5px #dedede;
    text-align: $text-align;
    color: $text-dark;
    font-size: $font-size-18;
    font-family: $font-family;
    font-weight: $font-weight-Regular;
    position: relative;
    height: inherit;
    .footer-logo {
        img {
            max-width: 401px;
            width: $full-width;
        }
    }
    .top-footer {
        // padding: 20px $padding;
      
        .footer-logo {
            // padding-bottom: 15px;
            // margin-bottom: 15px;
            padding: 36px 0px;
            border-bottom: 1px solid #9ab9d3;
            img {
                // width: $full-width;
                // max-width: 300px;
                width: auto;
                height: auto;
                // object-fit: cover;
                // object-position: top;
            }
        }
        a {
            color: $white-color;
            font-size: $font-size-18;
            font-family: $font-family;
            font-weight: $font-weight-Light;
            transition: $transition;
            &:hover {
               color: $color;
            }
        }
        li {
            p {
                font-weight: $font-weight-Light;
            }
        }
    }
    .color-light{
        background: rgba(266,266,266,0.2);
        color: $text-dark;
        padding: 36px 0px;
        .light-clr-txt {
            display: inline-block;
            vertical-align: middle;
            text-align: left;
            padding:6px 15px;
        
        }
        a {
            color: $text-dark;
            font-size: 16px;
            &:hover {
                color: $text-dark;
            }
        }
        p{
            font-size: 16px;
        }
        ul {
            margin-bottom: 0px;
        }
    }
    .bottom-footer {
        // padding: 15px 0;
        // background: #023f61;
        width: $full-width;
        position: relative;
        height: inherit;
        p {
            width: $full-width;
            max-width: 900px;
            margin: $margin-auto;
            font-size: $font-size-19;
            padding: 36px 15px;
            a {
                color: #01a1fa;
                transition: $transition;
                &:hover {
                    color: $white-color;
                }
            }
        }
    }
    p {
        margin: $margin;
    }
}

@media only screen and (max-width: 767px) {
    .newsletter {
        padding: 40px $padding;
        h3 {
            font-size: $font-size-34;
        }
    }
}

@media only screen and (max-width: 575px) {
    .light-clr-txt {
        width:100%
    
    }
    .newsletter {
        padding: 40px $padding;
        h3 {
            font-size: $font-size-28;
        }
        form {
            .form-group {
                display: $display;
                input {
                    margin-bottom: $margin-bottom-10;
                    border-radius: $border-radius;
                }
                button {
                    max-width: $full-width;
                    margin-bottom: $margin-bottom-10;
                    border-radius: $border-radius;
                }
            }
        }
    }
    .site-footer {
        .bottom-footer {
           p {
               font-size: $font-size-16;
            }
        } 
    }
}


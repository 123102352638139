
@import '../../assets/variables.scss';

.checkout-model {
    .pay-button {
        background: $bg-white !important;
        border: 1px solid $bg-dark !important;
        color: $bg-dark;
        padding: 12px !important;
        font-weight: 500;
        &:hover {
            background: $bg-dark !important;
            color: $bg-white;
        }
    }
    .modal-title {
        font-size: $font-size22 !important;
        font-family: $font-family !important;
    }
    .modal-body {
        padding: 30px 25px;
        ul {
            padding: 0 0 15px 0;
            list-style: none;
            margin: 0px 0 15px 0;
            text-align: left;
            border-bottom: 1px solid #e2e2e2;
        }
        li {
            display: $display-flex;
            justify-content: $justify-content;
            text-align: left;
            font-size: $font-size22;
            font-family: $font-family;
            color: $secondary-color;
            margin-bottom: 5px;
            span {
                span {
                    font-size: $font-size-15;
                }
            }
            &:last-child {
                font-weight: $font-weight-bold;
            }
        }
        form {
            display: $display-flex;
            align-items: $align-items;
            margin: 20px 0;
            .form-group {
                width: $full-width;
                margin-right: 15px;
                margin-bottom: $margin;
                input#coupon-code {
                    border: 1px solid #b7b7b7;
                    width: $full-width;
                    margin: $margin;
                }
            }
            button {
                width: $full-width;
                max-width: 144px;
                height: 45px;
                border: 1px solid $bg-dark;
                background: $bg-white;
                color: $text-dark;
                border-radius: $border-radius;
                font-weight: $font-weight-Medium;
                font-size: $font-size-18;
                letter-spacing: 1px;
                transition: $transition;
                margin: $margin;
                &:hover {
                    color: $text-white;
                    background: $bg-dark;
                }
            }
        }
        button {
            background: $transparent;
            border: $border;
            width: $full-width;
            padding: $padding;
            margin-bottom: 15px;
            img {
                width: $full-width;
            }
        }
        p {
            font-style: italic;
            font-weight: $font-weight-bold;
            color: #b6babd;
            font-size: $font-size-14;
            margin: $margin;
        }
    }
}
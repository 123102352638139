@import '../assets/variables.scss';

@mixin align-center {
	display: flex;
    flex-wrap: wrap;
	align-items: center;
}

@mixin save-btn {
    background: $primary-color;
    padding: 4px 34px;
    font-size: $font-size-17;
    font-weight: $font-weight-Semi-bold;
    font-family: $font-family;
    border-color: $primary-color;
    transition: $transition;
    margin-right: $margin-bottom-10;
    border-radius: $border-radius !important;
    &:hover {
       background: $transparent; 
       color: $primary-color;
    }
}

@mixin cancel-btn {
    background: $white-color;
    padding: 4px 34px;
    font-size: $font-size-17;
    font-weight: $font-weight-Semi-bold;
    font-family: $font-family;
    border-color: #c1c1c1;
    transition: $transition;
    margin-right: $margin-bottom-10;
    border-radius: $border-radius !important;
    color: #696969;
    &:hover {
       background: #696969; 
       color: $white-color;
       border-color: #696969;
    }
}
button.rename-btn.btn.btn-secondary, button.rename-btn-active.btn.btn-secondary {
	background: #f86f11;
	border-color: #f86f11;
    margin: 0 5px;
	&:hover {
		background: $transparent;
		color: #f86f11;
		svg {
			fill: #f86f11;
		}
	}
}
button.merge-btn.btn.btn-secondary, button.merge-btn-active.btn.btn-secondary  {
	background: $primary-color;
	border-color: $primary-color;
    margin: 0 5px;
	&:hover {
		background: $transparent;
		color: $primary-color;
		svg {
			fill: $primary-color;
		}
	}
}
button.deletes-btn.btn.btn-secondary, button.deletes-btn-active.btn.btn-secondary {
	background: #dc3545;
	border-color: #dc3545;
    margin: 0 5px;
	&:hover {
		background: $transparent;
		color: #dc3545;
		svg {
			fill: #dc3545;
		}
	}
}
button.deletes-btn.btn.btn-secondary svg, button.merge-btn.btn.btn-secondary svg, button.rename-btn.btn.btn-secondary svg , button.deletes-btn-active.btn.btn-secondary svg, button.merge-btn-active.btn.btn-secondary svg, button.rename-btn-active.btn.btn-secondary svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    fill: #fff;
}
button.deletes-btn-active.btn.btn-secondary svg {
    fill:#dc3545;
}
 button.merge-btn-active.btn.btn-secondary svg {
    fill: $primary-color;
}
 button.rename-btn-active.btn.btn-secondary svg {
    fill: #f86f11;

}
body {
    .content-wrapper {
        .breadcrumb {
            @include align-center;
            padding: 0;
            margin-bottom: $margin-bottom-30;
            list-style: none;
            background-color: $transparent;
            border-radius: 0;
            .breadcrumb-item + .breadcrumb-item {
                &::before {
                    content: "|";
                    color: $primary-color;
                }
            }
            .breadcrumb-item {
                color: $secondary-color;
                a {
                    color: $primary-color;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
            .breadcrumb-item.active {
                &::before {
                    color: $secondary-color;
                }
            }
        }
        .top-bar {
            justify-content: space-between;
            @include align-center;
            border-bottom: 1px solid #d3d3d3;
            padding-bottom: 12px;
            h3 {
                margin: $margin;
                font-size: $font-size-24;
                font-weight: $font-weight-Semi-bold;
                font-family: $font-family;
                color: $secondary-color;
                display: flex;
                align-items: center;
                p.entity-id {
                    margin: $margin;
                    background: $white-color;
                    color: $secondary-color;
                    padding: 2px 12px;
                    border-radius: $border-radius;
                    font-size: $font-size-16;
                    font-family: $font-family;
                    font-weight: $font-weight-Regular;
                    width: $full-width;
                    max-width: 170px;
                    text-align: $text-align;
                    margin-left: 8px;
                    border: 1px solid #c5c5c5;
                }
            }
        }
        .card {
            ul {
                padding: 0;
                margin: 10px 0 0 0;
                li {
                    list-style: none;
                    display: inline-block;
                    margin: 0 10px 0 0;
                    button {
                        padding: 2px 12px;
                        font-family: $font-family;
                        font-weight: $font-weight-Regular;
                        color: $white-color;
                        font-size: $font-size-17;
                        transition: $transition;
                        svg {
                            width: 16px;
                            height: 16px;
                            margin-right: 5px;
                            fill: $white-color;
                        }
                    }
                    button.rename-btn {
                        background: #f86f11;
                        border-color: #f86f11;
                        &:hover {
                            background: $transparent;
                            color: #f86f11;
                            svg {
                                fill: #f86f11;
                            }
                        }
                    }
                    button.merge-btn {
                        background: $primary-color;
                        border-color: $primary-color;
                        &:hover {
                            background: $transparent;
                            color: $primary-color;
                            svg {
                                fill: $primary-color;
                            }
                        }
                    }
					
                    button.deletes-btn {
                        background: #dc3545;
                        border-color: #dc3545;
                        &:hover {
                            background: $transparent;
                            color: #dc3545;
                            svg {
                                fill: #dc3545;
                            }
                        }
                    }
                }
            }
            .add-card {
                border: 3px dashed #e6e6e6;
                padding: $margin-bottom;
                text-align: $text-align;
                border-radius: $border-radius;
                svg {
                    width: 25px;
                    height: 25px;
                    margin-bottom: 5px;
                }
            }
        }
        .btn-group {
            display: $display;
            text-align: $text-align;
            margin-top: $margin-bottom-30;
            button.save-btn.btn.btn-secondary {
                @include save-btn;
            }
            button.cancel-btn.btn.btn-secondary {
                @include cancel-btn;
            }
        }
    }
}


@media only screen and (max-width: 768px) {
    body {
    .content-wrapper {
           .top-bar {
                h3 {
                    margin-bottom: $margin-bottom-10;
                    display: $display;
                    p.entity-id {
                        margin: 10px 0 0 0;
                    }
                }
                ul {
                  margin-top: $margin-bottom;
                }
            }
            .card {
                ul {
                    li {
                        button.deletes-btn {
                            margin-top: $margin-bottom-10;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
  body {
    .content-wrapper {
           .top-bar {
                h3 {
                    margin-bottom: $margin-bottom-30;
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    body {
    .content-wrapper {
           .top-bar {
                h3 {
                    margin-bottom: $margin-bottom-10;
                    display: block;
                    p.entity-id {
                        margin: 10px 0 0 0;
                    }
                }
            }
            .card {
                ul {
                    li {
                        button.deletes-btn {
                            margin-top: $margin-bottom-10;
                        }
                    }
                }
            }
        }
    }
}

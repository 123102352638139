.buy-evoshield {
    padding: 90px 0 40px;
    background: #fff;
    min-height: 88vh;
    .form-steps-actions {
        .btn {
            background-color: #fff;
            border-width: 1px;
            border-color: #000;
            color: #000;
            padding: 10px 25px;
            &:hover {
                background-color: #000;
                border-color: #000;
                color: #fff;
            }
        }
    }
    .location_container {
        margin-top: 70px;
        text-align: center;
    }
    .step_last {
        margin-top: 70px;
    }
    .step_one {
        margin-top: 70px;
        #three-container {
            width: 60%;
            height: 400px;
            display: inline-block;
            margin-right: 5px;
            overflow: hidden;
        }
        .card-body {
            padding: 0;
        }
        .card-header {
            background: #000;
            color: #fff;
            padding: 5px 10px;
            text-align: center;
        }
    }
    .dropdown-picker {
        width: 100%;
        height: 30px;
        display: inline-block;
        background-color: #eaeaea;
        border-top: 1px solid white;
        .dropdown-name {
            height: 100%;
            width: 70%;
            display: inline-block;
            vertical-align: top;
            padding-left: 5px;
            text-align: left;
        }
        .color-part {
            vertical-align: top;
            text-align: right;
            height: 30px;
            min-width: 150px;
            display: inline-block;
            color: grey;
            right: 0px;
            position: absolute;
            .color-name {
                height: 100%;
                display: inline-block;
                margin-right: 15px;
            }
            .selected-color-box {
                width: 30px;
                height: 100%;
                display: inline-block;
                border: 1px solid grey;
                vertical-align: top;
            }
            .display-dropdown {
                width: 30px;
                height: 100%;
                display: inline-block;
                vertical-align: top;
                border: none;
            }
        }
    }
    .dropdown-color-list
    {
        width: 100%;
        display: inline-block;
        border-bottom: 1px solid grey;
        text-align: left;
        .color-value{
            margin-top: 5px;
            margin-left: 5px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 5px;
            border: 1px solid #000;
        }
    }
    // margin-top: 75px;
    .product-info {
        margin-top: 75px;
    }
    .step-information {
        margin-top: 35px;
        h3 {
            font-size: 22px;
            font-weight: 400;
        }
    }
    .product-name {
        margin-bottom: 10px;
        h4 {
            margin: 0;
        }
        h3 {
            margin: 0;
        }
    }
    .product-cost {
        margin-bottom: 10px;
        h3 {
            margin: 0;
        }
        p {
            margin: 0;
        }
    }
    .plan-step-information {
        margin-top: 75px;
    }
    .buy-now-button {
        padding: 10px 25px;
        border-width: 1px;
        border-style: solid;
        background-color: #fff;
        color: #000;
        border-color: #000;
        &:hover {
            background-color: #000;
            color: #fff;
            border-color: #000;
        }
    }
}

@media screen and (max-width: 578px) {
    .buy-evoshield {
        .product-info {
            margin-top: 30px;
        }
        .product-name {
            margin-bottom: 20px;
            h3 {
                font-size: 23px;
            }
            h4 {
                font-size: 17px;
            }
        }

        .product-cost {
            h3 {
                font-size: 23px;
            }
            p {
                font-size: 16px;
            }
        }
        .plan-step-information {
            margin-top: 30px;
        }
        .details-cards {
            .card-header {
                font-size: 20px;
            }
            .card-body {
                font-size: 15px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .buy-evoshield {
        .form-steps-actions {
            .btn {
                height: 100%;
                 width: 100%;
            }
        }
        .product-info {
            margin-top: 30px;
        }
        .product-name {
            margin-bottom: 20px;
            h3 {
                font-size: 23px;
            }
            h4 {
                font-size: 17px;
            }
        }

        .product-cost {
            h3 {
                font-size: 23px;
            }
            p {
                font-size: 16px;
            }
        }
        .plan-step-information {
            margin-top: 30px;
        }
        .details-cards {
            .card-header {
                font-size: 20px;
            }
            .card-body {
                font-size: 15px;
            }
        }
    }
}

.map_location
{
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
}

.leaflet-container {
    overflow: hidden;
    height: 100%;
    width: 100%;
    z-index: 0;
    }
.map-tooltip
{
    text-align: center;
}
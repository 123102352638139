@import "../../assets/variables.scss";

@mixin align-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

@mixin button {
    background: $primary-color;
    padding: 6px 20px;
    font-size: $font-size-17;
    font-weight: $font-weight-Semi-bold;
    font-family: $font-family;
    border-color: $primary-color;
    transition: $transition;
    border-radius: $border-radius !important;
    &:hover {
        background: $transparent;
        color: $primary-color;
    }
}

@mixin btn-secondary {
    background: $transparent;
    padding: 6px 20px;
    border-color: $primary-color;
    color: $primary-color;
    font-weight: $font-weight-Semi-bold;
    font-family: $font-family;
    font-size: $font-size-17;
    transition: $transition;
    &:hover {
        background: $primary-color;
        color: $white-color;
    }
}

body {
    .btn {
        &:focus {
            box-shadow: none;
        }
    }
    .content-wrapper {
        .inspdfbtn{
            background: $primary-color;
            padding: 6px 20px;
            font-size: 17px;
            font-weight: 600;
            font-family: $font-family;
            border-color: $primary-color;
            transition: all .5s;
            border-radius: 5px!important;
        }
        .insdownloadBtn{
            width: 50%;
            display: inline-block;
            text-align: right;
            vertical-align: middle;
        }
        .teampagetext{
            vertical-align: middle;
            margin-bottom: 0px !important;
        }
        .purpose-hidden {
            // display: $display-flex;
            align-items: $align-items;
            justify-content: $justify-content-center;
            // margin-bottom: $margin-bottom-30;
            // width: 50%;
            display: inline-block;
            .empty{
                width: 25%;
                height: 100%;
                float: left;
            }
            .generate-button{
                width: 25%;
                height: 100%;
                float: left;
            }
            .purpose-heading{
                width: 50%;
                float: left;
                h1 {
                    margin: $margin;
                    font-weight: $font-weight-Semi-bold;
                    font-family: $font-family;
                    // font-size: $font-size-28;
                    color: $secondary-color;
                    p {
                        font-size: $font-size-16;
                        margin-bottom: $margin;
                        margin-top: 5px;
                        font-weight: $font-weight-Semi-bold;
                        color: #2d2d2d;
                        span {
                            font-weight: $font-weight-Regular;
                        }
                    }
                }
            }
            button.pro {
                @include btn-secondary;
                margin-right: $margin-bottom-10;
                svg {
                    width: 16px;
                    height: 18px;
                    position: $position-rel;
                    top: -1px;
                    right: 7px;
                    fill: $primary-color;
                }
                &:hover {
                    svg {
                        fill: $white-color;
                    }
                }
            }
            button.down {
                @include button;
            }
        }
        .toggleswitch.data{
            width: auto !important;
            display: inherit !important;
            // width: 50%;
            // display: inline-block;
            text-align: right;
            margin-top: 10px;
            span {
                padding: 0px 7px;
                font-weight: bold;
            }
            div {
                vertical-align: middle;
                .react-switch-handle {
                    height: 20px !important;
                    width: 20px !important;
                }
                .react-switch-bg {
                    background: $primary-color !important;
                    height: 23px !important;
                    width: 50px !important;
                }
            }
            .activeToggle {
                color: gray;
            }
        }
        .purpo-hidden {
            display: $display-flex;
            align-items: $align-items;
            justify-content: $justify-content;
            margin-bottom: $margin-bottom-30;
            h3 {
                margin: $margin;
                font-weight: $font-weight-Semi-bold;
                font-family: $font-family;
                font-size: $font-size-24;
                color: $secondary-color;
                p {
                    font-size: $font-size-16;
                    margin-bottom: $margin;
                    margin-top: 5px;
                    font-weight: $font-weight-Semi-bold;
                    color: #2d2d2d;
                    span {
                        font-weight: $font-weight-Regular;
                    }
                }
            }
            button.pro {
                @include btn-secondary;
                margin-right: $margin-bottom-10;
                svg {
                    width: 16px;
                    height: 18px;
                    position: $position-rel;
                    top: -1px;
                    right: 7px;
                    fill: $primary-color;
                }
                &:hover {
                    svg {
                        fill: $white-color;
                    }
                }
            }
            button.down {
                @include button;
            }
        }
        
        .purpo {
            .legend-button
            {
                background-color: #2b4fc4e5;
                border: none;
                margin-right: 5px;
            }
            .legend-angular-chart
            {
                width: 80%;
                height: calc(100% - 60px);
                position: absolute;
                top: 10px;
                left: 50%;
                transform: translate(-50%, 0);
                z-index: 1000;
                background-color: white;
                border: 1px solid gray;
                overflow-y: auto;
            }
            .legend-wrapper
            {
                text-align: right; 
                height: 40px; 
            }
            .data-card {
                justify-content: end;
                padding: $padding;
                .events-chart {
                    width: 100%;
                    height: 100%;
                    img {
                        width: $full-width;
                        height: 100%;
                    }
                    .btn-group {
                        display: $display-flex;
                        align-items: $align-items;
                        justify-content: $justify-content;
                        max-width: 700px;
                        margin: 30px auto;
                        .btn {
                            background: $primary-color;
                            font-size: 9px;
                            font-weight: $font-weight-Semi-bold;
                            font-family: $font-family;
                            border-color: $primary-color;
                            transition: $transition;
                            border-radius: $border-radius !important;
                            position: $position-rel;
                            top: -2px;
                            margin-right: $margin-bottom-10;
                            width: $full-width;
                            max-width: 48px;
                            padding: $padding;
                            height: 47px;
                            &:hover {
                                background: $transparent;
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
            .mps-chart {
                padding: $padding;
                justify-content: end;
                .img-box {
                    text-align: $text-align;
                    img {
                        width: $full-width;
                    }
                }


                .chart-wrapper
                {
                    margin-top: 15px;
                    height: calc(100% - 10px);
                    width: 100%;
                }

                .no-mldata-message {
                    position: absolute;
                    left: 50%;
                    top: 40%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    text-align: center;
                    font-size: 16px;
                    color: #595959;
                    font-family: $font-family;
                }

                .get-started-button
                {
                    margin-top: 15px;
                    background: $primary-color;
                    padding: 6px 20px;
                    font-size: 18px;
                    font-family: $font-family;
                    border-color: $primary-color;
                    transition: all 0.5s;
                    border-radius: 5px !important;
                }
                img {
                    padding: 15px;
                }
                label {
                    margin-bottom: $margin;
                    position: $position-rel;
                    select {
                        //width: auto !important;
                        height: 38px !important;
                        color: $secondary-color !important;
                        padding: 0 35px 0 15px !important;
                        font-size: 13px !important;
                        font-family: $font-family !important;
                        border: 1px solid #d6d9da;
                        border-radius: $border-radius;
                    }
                    // &::after {
                    //     content: " ";
                    //     width: 0;
                    //     height: 0;
                    //     border-left: 6px solid transparent;
                    //     border-right: 6px solid transparent;
                    //     border-top: 6px solid #777878;
                    //     position: $position-absol;
                    //     top: 14px;
                    //     right: 10px;
                    //     border-radius: $border-radius;
                    // }
                }
                .titel-sel {
                    padding: 10px 15px;
                    border-bottom: 1px solid #e8e8e8;
                    height: 60px;
                    
                    margin: $margin;
                    .card-title {
                        font-size: $font-size-16 !important;
                        font-weight: $font-weight-Semi-bold !important;
                    }
                    select {
                        -webkit-appearance: none;
                    }
                }
            }

            .widget-container {
                border: 1px solid black;
                position: relative;
                display: flex;
                flex-direction: column;
                min-width: 0;
                word-wrap: break-word;
                background-color: #fff;
                background-clip: border-box;
                border: 1px solid rgba(0, 0, 0, 0.125);
                border-radius: 5px;
            }
        }

        .accel-events {
            // margin-top: 30px;
            .event {
                display: $display-flex;
                justify-content: $justify-content;
                align-items: $align-items;
                h3 {
                    font-size: $font-size-28;
                    font-family: $font-family;
                    font-weight: $font-weight-Semi-bold;
                    color: $secondary-color;
                    margin-bottom: $margin;
                }
                .events-filter {
                    display: $display-flex;
                    align-items: $align-items;
                    h3 {
                        font-size: $font-size-18;
                        font-family: $font-family;
                        font-weight: $font-weight-Semi-bold;
                        color: $secondary-color;
                        margin-bottom: $margin;
                    }
                    ul {
                        display: $display-flex;
                        margin: $margin;
                        padding: $padding;
                        li {
                            list-style: none;
                            margin-left: $margin-bottom-10;
                            label {
                                margin-bottom: $margin;
                                position: $position-rel;
                                select {
                                    height: 35px;
                                    color: $secondary-color;
                                    border: 1px solid #d6d9da;
                                    border-radius: $border-radius;
                                    padding: 0 35px 0 12px;
                                    font-size: $font-size-16;
                                    font-family: $font-family;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    background: $white-color;
                                    font-weight: $font-weight-Regular;
                                    &:focus {
                                        outline: 0;
                                    }
                                }
                                // &::after {
                                //     content: " ";
                                //     width: 0;
                                //     height: 0;
                                //     border-left: 6px solid transparent;
                                //     border-right: 6px solid transparent;
                                //     border-top: 6px solid $secondary-color;
                                //     position: $position-absol;
                                //     top: 14px;
                                //     right: 10px;
                                //     border-radius: $border-radius;
                                // }
                            }
                        }
                    }
                }
            }
            .table {
                th,
                td {
                    border: 1px solid #dee2e6;
                    font-size: $font-size-16;
                    font-family: $font-family;
                    font-weight: $font-weight-Semi-bold;
                    color: $secondary-color;
                }
                td {
                    font-weight: 400;
                    button.expot {
                        @include btn-secondary;
                        display: $display;
                        width: $full-width;
                        max-width: 153px;
                        margin: 0 auto 15px;
                    }
                    button.details {
                        @include button;
                        display: $display;
                        width: $full-width;
                        max-width: 153px;
                        margin: $margin-auto;
                    }
                    &:first-child {
                        text-align: $text-align;
                    }
                }
            }
        }
    }
}
.chart-bottom-player-text {
    text-align: center;
    font-size: 14px;
    color: gray;
    font-weight: 600;
    padding: 5px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
}
@media only screen and (max-width: 1298px) {
    body .content-wrapper .purpo .mps-chart .titel-sel {
        height: 80px;
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    body {
        .content-wrapper {
            .purpose-hidden {
                display: $display;
                .purpose-heading{
                    width: 50%;
                    float: left;
                    h1 {
                        margin: $margin;
                        font-size: 27px;
                        margin-bottom: $margin-bottom;
                    }
                }
                .insdownloadBtn{
                    width: 100%;
                }
                .inspdfbtn {
                    font-size: 15px !important
                }
            }
            .purpo {
                .mps-chart {
                    .titel-sel {
                        height: auto;
                    }
                }
            }
            .accel-events {
                clear: both;
                .event {
                    display: flex;
                    h3 {
                        width: 70%;
                        font-size: 20px;
                        margin-bottom: $margin-bottom-10;
                    }
                    .toggleswitch {
                        width: 30%;
                    }
                    .events-filter {
                        display: $display;
                        ul {
                            overflow: auto;
                            li {
                                margin-right: $margin-bottom-10;
                                margin-left: $margin;
                            }
                        }
                    }
                }
                .table {
                    th,
                    td {
                        font-size: $font-size-15;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    body {
        .content-wrapper {
            .purpose-heading{
                width: 50%;
                float: left;
                h1 {
                    margin: $margin;
                    font-size: 23px;
                    margin-bottom: $margin-bottom;
                    p{
                        font-size: 12px
                    }
                }
            }
            .insdownloadBtn{
                width: 100%;
            }
            .inspdfbtn {
                font-size: 12px !important
            }
            .btn.down {
                margin-top: $margin-bottom-10;
            }
            .purpo {
                .events-chart {
                    .btn-group {
                        display: $display !important;
                        .btn {
                            margin-bottom: $margin-bottom-10;
                        }
                    }
                }
                .mps-chart {
                    .titel-sel {
                        height: auto;
                    }
                }
            }
            .accel-events {
                clear: both;
                .event {
                    display: flex;
                    h3 {
                        width: 70%;
                        font-size: 19px;
                        margin-bottom: $margin-bottom-10;
                    }
                    .toggleswitch {
                        width: 30%;
                        font-size: 14px;
                        padding: 5px 0
                    }
                    .events-filter {
                        display: $display;
                        ul {
                            overflow-x: auto;
                            li {
                                margin-right: $margin-bottom-10;
                                margin-left: $margin;
                            }
                        }
                    }
                }
                .event-chart {
                    overflow-x: auto;
                    .table {
                        width: 1050px;
                        th,
                        td {
                            font-size: $font-size-15;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) {
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
// Variables
$PrimaryColor: #08416e;
$Space10: 10px;
$transparent: transparent;
$margin-bottom-30: 30px;
$margin-bottom-10: 10px;
$fill-height: 100vh;
$full-height: 100%;
$full-width: 100%;
// $font-family: 'Poppins', sans-serif;
$font-family: "Roboto", sans-serif;
$secondary-color: #000;
$primary-color: #1084dc;
$color: #007bff;
$text-color: #212529;
$par-color: #848484;
$font-weight-Regular: 400;
$font-weight-Medium: 500;
$font-weight-Semi-bold: 600;
$font-weight-bold: 700;
$white-color: #fff;
$text-align: center;
$transition: all 0.5s;
$text-transform: uppercase;
$font-family-source: "Source Sans Pro", sans-serif;
$margin-bottom: 20px;
$border-radius: 5px;
$border-radius-100: 100%;
$font-size-20: 20px;
$font-size-18: 18px;
$font-size-16: 16px;
$font-size-14: 14px;
$font-size-24: 24px;
$font-size-34: 34px;
$font-size22: 22px;
$font-size-28: 28px;
$font-size-19: 19px;
$font-size-17: 17px;
$font-size-15: 15px;
$margin: 0;
$padding: 0;
$border: 0;
$font-family-roboto: "Roboto", sans-serif;
$box-shadow: 0 3px 10px 0 #d4d4d4;
$width-20: 20px;
$height-20: 20px;
$position-rel: relative;
$position-absol: absolute;
$display: block;
$margin-auto: 0 auto;
$display-flex: flex;
$justify-content: space-between;
$align-items: center;
$justify-content-center: center;
.subscription-checkbox{
    max-width: 75%;
    margin: auto;
    display: flex;
}
.subscription-checkbox svg{
    display: inline-block;
    margin-bottom: 34px;
    margin-right: 23px;
    width: 30px;
    height: 31px;
    float:left;
}
.subscription-checkbox span{
    line-height: normal;
}
.modal-content {
    max-width: 515px;
    margin: 0 auto;
    border: $padding;
    .modal-header {
        padding: 15px 25px;
        border-color: #e2e2e2;
        .modal-title {
            margin-bottom: 0;
            line-height: 1.5;
            font-weight: $font-weight-Semi-bold;
            font-family: $font-family;
            font-size: $font-size-18;
            color: $secondary-color;
        }
        button {
            background: $transparent;
            border: $padding;
            padding: $padding;
            svg {
                width: 12px;
                height: 12px;
                fill: #6d6d6d;
            }
            &:focus,
            &:active {
                background: $transparent;
                border: $padding;
                padding: $padding;
                outline: 0;
            }
        }
    }
    .modal-body {
        padding: 30px 40px;
        text-align: $text-align;
        h3 {
            font-weight: $font-weight-Semi-bold;
            font-family: $font-family;
            font-size: $font-size-20;
            color: $secondary-color;
        }
        p {
            font-weight: $font-weight-Regular;
            font-family: $font-family;
            font-size: $font-size-18;
            color: #4a4a4a;
            margin-bottom: $margin-bottom-30;
        }
        input:not([type=checkbox]):not([type=radio]),
        select {
            height: 45px;
            margin-bottom: $margin-bottom;
            color: #797878;
            font-family: $font-family;
            font-weight: $font-weight-Regular;
            border-color: #e2e2e2;
            width: $full-width;
            &:focus {
                box-shadow: none;
                border-color: #e2e2e2;
                outline: 0;
            }
        }
        label {
            display: $display;
            text-align: left;
        }
        .save-btn {
            background: $primary-color;
            padding: 4px 34px;
            font-size: 17px;
            font-weight: $font-weight-Semi-bold;
            font-family: $font-family;
            border: 1px solid $primary-color;
            transition: $transition;
            margin-right: $margin-bottom-10;
            border-radius: $border-radius !important;
            color: $white-color !important;
            &:hover {
                background: $transparent;
                color: $primary-color !important;
            }
        }
        .delete-popup-btn {
            background: red;
            padding: 4px 34px;
            font-size: 17px;
            font-weight: $font-weight-Semi-bold;
            font-family: $font-family;
            border: 1px solid red;
            transition: $transition;
            margin-right: $margin-bottom-10;
            border-radius: $border-radius !important;
            color: $white-color !important;
            &:hover {
                background: $transparent;
                color: red !important;
            }
        }
        .cancel-btn {
            background: $white-color;
            padding: 4px 34px;
            font-size: 17px;
            font-weight: $font-weight-Semi-bold;
            font-family: $font-family;
            border: 1px solid #c1c1c1;
            transition: $transition;
            margin-right: $margin-bottom-10;
            border-radius: $border-radius !important;
            color: #696969;
            &:hover {
                background: #696969;
                color: $white-color;
                border-color: #696969;
            }
        }
        .take-photo {
            img {
                width: 100%;
            }
            .pic-details {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $primary-color;
                padding: 15px;

                ul {
                    list-style: none;
                    text-align: left;
                    padding: $padding;
                    margin: $margin;
                    li {
                        font-family: $font-family;
                        font-size: $font-size-17;
                        color: $white-color;
                        font-weight: $font-weight-Regular;
                        line-height: normal;
                    }
                }
                button.camera-360 {
                    background: $transparent;
                    border: $border;
                    padding: $padding;
                    svg {
                        width: 55px;
                        height: 55px;
                        fill: $white-color;
                    }
                }
                button.click-btn {
                    border: $border;
                    background: $transparent;
                    padding: $padding;
                }
            }
        }
    }
}

.take-model {
    .modal-content {
        max-width: 647px;
        .show-brain {
            background: $primary-color;
            padding: 6px 34px;
            font-size: $font-size-20;
            font-weight: $font-weight-Semi-bold;
            font-family: $font-family;
            border: 1px solid $primary-color;
            transition: $transition;
            margin-right: $margin-bottom-10;
            border-radius: $border-radius !important;
            color: $white-color !important;
            margin-top: $margin-bottom-30;
            &:hover {
                background: $transparent;
                color: $primary-color !important;
            }
        }
    }
}


.profile-user-name {
    background: #000;
    color: #fff;
    font-weight: 600;
    width: 45px;
    height: 45px;
    font-size: 18px;
    line-height: 47px;
    letter-spacing: -1px;
    text-transform: capitalize;
}

.react-html5-camera-photo > img,
.react-html5-camera-photo > video {
    width: 100% !important;
    height: 100% !important;
}
.take-photo {
    position: relative;
    .pic-details {
        position: absolute;
        width: 100%;
        margin-top: -82px;
    }
    .oval-outer{
        display: flex;
        justify-content: center;
        align-items: center;
        }
        .focus-icon {
            // position: absolute;
            // width: 40% !important;
            // top: 18%;
            // z-index: 99;
            // margin: auto;
            // left: 0;
            // right: 0;
            position: absolute;
            width: 40% !important;
            top: 6%;
            z-index: 99;
            margin: auto;
        }
}
#outer-circle {
    margin-top: 16px;
}

.image-preview {
    width: 100%;
    img {
        width: 100%;
    }
    .clearfix {
        background: #1084dc;
    }
}

.avatar-model-dialog {
    max-width: 692px !important;
}
.form-password{
    display:block;
}
.icon-image{
    margin-right: 10px;
}
.optional-register{
                text-align: center;
                color: grey;
                font-weight: 500;
                font-size: 26px;
            
            }
.avatar-model {
    max-width: 692px;
    .modal-content {
        max-width: 692px;
    }
    .take-photo {
        height: 455px;
        background-color: #333;
    }
    canvas {
        background-color: white;
    }
    .loading {
        height: 370px;
    }
    .CanvasContainer {
        height: 455px;
    }
    .modal-body {
        height: 455px;
    }
    .show-brain {
        position: absolute;
        bottom: 0;
        left: 38%;
    }
}
.no-item-message {
    text-align: center;
    color: #4c4c4c;
    width: 100%;
    font-size: 20px;
}
.show-brain {
    background: #1084dc;
    padding: 6px 34px;
    font-size: 20px;
    font-weight: 600;
    font-family: $font-family;
    border: 1px solid #1084dc;
    transition: all 0.5s;
    margin-right: 10px;
    border-radius: 5px !important;
    color: #fff !important;
    margin-bottom: 30px;
}
.phone-number-input {
    width: 100% !important;
    height: 48px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    padding: 0px 54px !important;
    border-radius: 5px !important;
    margin-bottom: 20px !important;
}

// pnding bg ...
.bg-simulation-pending {
    background: #f86f11 !important;
    .entity-id {
        background: #fff !important;
        color: #000 !important;
    }
    .title {
        color: #fff !important;
    }
    .simulations {
        color: #fff !important;
        span {
            color: #fff !important;
        }
    }
}
.bg-simulation-pending-tb {
    background: #f86f11 !important;
    td {
        color: #fff !important;
    }
}
.player-bg-pending {
    background: #f86f11 !important;
    td {
        color: #fff !important;

        input {
            background: #fff !important;
        }
    }
}

.player-bg-complete {
    background: #2d9b46 !important;
    td {
        color: #fff !important;

        input {
            background: #fff !important;
        }
    }
}

.player-bg-failed {
    background: rgb(179, 46, 46) !important;
    td {
        color: #fff !important;

        input {
            background: #fff !important;
        }
    }
}

// complete bg ..
.bg-simulation-complete {
    background: #2d9b46 !important;
    .entity-id {
        background: #fff !important;
        color: #000 !important;
    }
    .title {
        color: #fff !important;
    }
    .simulations {
        color: #fff !important;
        span {
            color: #fff !important;
        }
    }
}

// failed bg ..
.bg-simulation-failed {
    background: rgb(179, 46, 46) !important;
    .entity-id {
        background: #fff !important;
        color: #000 !important;
    }
    .title {
        color: #fff !important;
    }
    .simulations {
        color: #fff !important;
        span {
            color: #fff !important;
        }
    }
}

.bg-simulation-completed-tb {
    background: #2d9b46 !important;
    td {
        color: #fff !important;
    }
}

.bg-simulation-failed-tb {
    background: rgb(179, 46, 46) !important;
    td {
        color: #fff !important;
    }
}

//...
.apply-metric-model {
    padding: 14px 26px !important;
    color: black;
    .Head {
        float: left;
        padding: 0;
        width: 100%;
        text-align: left;
    }
    .metric-checkbox {
        padding: 0;
        list-style-type: none;
        input {
            height: 20px !important;
            margin: 0 !important;
            width: 20px !important  ;
            padding: 0;
            // float: left;
            display: inline-block;
        }
        span {
            display: flex;
        }
    }
}

.brain-view-el {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // width: 100%;
    // height: 100%;
    // display: block;
}

.navbar-toggler-icon {
    height: 28px;
    width: 26px;
}

.main-menu {
    display: flex;
    flex-direction: column;
    padding-left: 3px;
    @media screen and (min-width: 992px) {
        flex: 1;
    }
}

#chartjs-tooltip {
    position: absolute;
    position: fixed;
    z-index: 999;
    background: #000000c7;
    color: white;
    border-radius: 10px;
    border-top-left-radius: inherit;
    font-size: 15px;
    letter-spacing: 1px;
}

.inspact-loading{
    p{
        color: rgb(255, 255, 255)!important;
        margin: 0!important;
        padding: 0!important;

    }
    padding: 10px;
    color: rgb(255, 255, 255);
    margin: 0;
    position: absolute;
    top: 40%;
    left: 45%;
}

@media only screen and (max-width: 1280px) {
    .avatar-model {
        margin-top: 70px;
    }
}

@media only screen and (max-width: 768px) {
    .brain-view-el {
        position: inherit;
        left: 0;
        top: 0;
        transform: inherit;
        // width: 100%;
        // height: 100%;
        // display: block;
    }
    .take-photo .focus-icon {
        width: 40% !important;
        top: 2%;
    }
}

@media only screen and (max-width: 480px) {
    .photo-modal {
        padding: 18px 2px !important;
    }

    // .take-photo .focus-icon {
    //     width: 100px !important;
    // }
    .take-photo .focus-icon {
        width: 60% !important;
        top: 3%;
    }
    .avatar-model .show-brain {
        position: absolute;
        bottom: 0;
        left: 28%;
    }
    .avatar-model .CanvasContainer {
        height: 453px;
    }
    .avatar-model .modal-body {
        height: 454px;
    }
}

@media only screen and (max-width: 375px) {
    .modal-content .modal-body .take-photo .pic-details ul li {
        font-size: 14px;
    }
    .take-photo .focus-icon {
        width: 55% !important;
        top: 3%;
    }
    #outer-circle {
        margin-top: 20px;
    }
    .take-photo .pic-details {
        margin-top: -75px;
    }
    .avatar-model .show-brain {
        left: 28%;
    }
    .avatar-model .CanvasContainer {
        height: 370px;
    }
    .avatar-model .modal-body {
        height: 370px;
    }
}

@media only screen and (max-width: 320px) {
    .take-photo .focus-icon {
        width: 50% !important;
        top: 3%;
    }
    .avatar-model .modal-body {
        height: 358px;
    }
    .avatar-model .take-photo {
        height: 292px;
    }
    .avatar-model .CanvasContainer {
        height: 292px;
    }
    .avatar-model .show-brain {
        left: 22%;
    }
}

.no-hover-background {
    &:hover {
        background-color: transparent !important;
        color: #000 !important;
    }
    
}

.btn-dark {
    background-color: transparent;
    border: 1px solid #000;
    color: #000;
    &:hover {
        background-color: #000;
        color: #fff;
    }
}